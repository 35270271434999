<template>
    <div class="inside">
        <van-steps :active="active">
            <van-step>Setp1</van-step>
            <van-step>Setp2</van-step>
        </van-steps>

        <div class="code" v-show="active == 0">
            <div>请点击下方体检兑换码</div>
            <van-button  color="#FBFBFB"  round icon="orders-o" type="primary" size="large" @click="copy">{{data}}</van-button>
        </div>

        <div class="code" v-show="active == 1">
            <div class="inf">
                体检兑换码<strong>{{this.data}}</strong>已为您自动复制
            </div>
            <!-- <div class="inf">点击下方按钮开始预约</div> -->
            <!-- <div class="inf">请按页面提示填写预约单</div>  -->
            <!-- <div class="inf"><strong>{{this.data}}</strong> 已为您复制至粘贴板</div> -->
            <van-button  color="#FBFBFB"  round  type="primary" size="large" @click="hrefUrl">点击填写体检预约单</van-button>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            active: 0,
            data:'',
            href:''
        }
    },
    created(){
        this.data = window.sessionStorage.getItem("thereCode")
        this.href = window.sessionStorage.getItem("thereCodeHref")
        if(!this.data){
            this.$router.push({path:'/home'})
        }
    },
    methods:{
        copy(){
            const copyDom = document.createElement("div");
            copyDom.innerText =this.data;
            copyDom.style.position = "absolute";
            copyDom.style.top = "0px";
            copyDom.style.right = "0px";
            copyDom.style.zIndex = "-10";
            copyDom.style.opacity = "0";
            document.body.appendChild(copyDom);

            // 创建选中范围
            const range = document.createRange();
            range.selectNode(copyDom);
            // 移除剪切板中内容
            window.getSelection().removeAllRanges();
            // 添加新的内容到剪切板
            window.getSelection().addRange(range);
            document.execCommand("copy");
            copyDom.parentNode.removeChild(copyDom);

            this.active = 1
        },
        hrefUrl(){
            window.location.href = this.href
        }
    },

}
</script>

<style lang="less" scoped>
    .inside{
        padding: 20px 20px;
    }
    .code{
        margin-top: 50px;
        text-align: center;
        font-size: 14px;
    }
    /deep/ .van-button{
        margin-top: 20px;
        font-weight: 700;
        color:black !important;
    }
    .inf{
        margin-top: 10px;
        font-size: 14px;
    }
    strong{
        color: #2589ee;
        padding: 0px 3px;
    }
</style>